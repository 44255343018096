import React from "react"
import styled from "@emotion/styled"

import AlertBanner from "../ui/alert-banner"
import WebpayBox from "./webpay-box"
import Webpay from "../ui/images/webpay-logo"
import WebpayBoxInternational from "./webpay-international"
import WebpayBoxMexico from "./webpay-mexico"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
`

type Props = {
  international?: boolean
  mexico?: boolean
}

const get_text_important = (mexico: boolean) => {
  if (mexico) {
    return (
      <>
        <strong>IMPORTANTE:</strong> Por el uso de Transbank, al realizar un depósito vía webpay, este tiene un costo de 1,9% con tarjeta de crédito y 0,9% con tarjeta de débito. Dicho costo será descontado del monto depositado.
        <br />
        <br />
        <strong>NO SE ACEPTAN PAGOS PROVENIENTES DE TARJETAS DE TERCEROS.</strong> Si esto sucede, el pago será anulado.
      </>
    )
  }

  return (
    <>
      <strong>IMPORTANTE:</strong> En algunos casos podrá existir un costo por procesamiento de hasta un 2,5%, el cual será descontado del monto depositado.
    </>
  )
}

const PayOnline = ({ international = false, mexico = false }: Props) => {
  let WebpayWrapper = WebpayBox
  if (international) {
    WebpayWrapper = WebpayBoxInternational
  }
  if (mexico) {
    WebpayWrapper = WebpayBoxMexico
  }

  return (
    <Content>

      <WebpayWrapper>
        <Webpay />
      </WebpayWrapper>

      <AlertBanner> {get_text_important(mexico)} </AlertBanner>
    </Content>
  )
}

export default PayOnline
