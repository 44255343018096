import React from 'react'
import styled from "@emotion/styled"

import instruments from "./instruments"
import operations from "./operations"
import timeframes from "./timeframes"
import { trackEvent } from '../../../../../helpers/tracker'

const CustomLink = styled.a`
  color: #FFF;
  text-decoration: underline;
`

const params: Field[] = [
  {
    label: "Instrumentos",
    name: "nemo",
    options: instruments,
    type: "select",
    defaultValue: "#AAPL",
  },
  {
    label: "Tipo de operación",
    name: "direction",
    options: operations,
    type: "select",
    tooltip: (
      <>
        ¿Qué es vender?
        <br />
        <CustomLink
          onClick={() => {
            trackEvent(`homeprospecto:simulador:tooltip:link-direccion`)
          }}
          href="https://blog.capitaria.com//s/f/que-significa-la"
        >
          Quiero saber más
        </CustomLink>
      </>
    ),
  },
  {
    label: "Tamaño operación",
    name: "size",
    type: "number",
    step: 0.1,
    required: true,
    options: [],
    tooltip: (
      <>
        Cantidad de lotes que deseas operar.
        <br />
        <CustomLink
          onClick={() => {
            trackEvent(`homeprospecto:simulador:tooltip:link-lotes`)
          }}
          href="https://blog.capitaria.com//s/f/que-es-un-lote"
        >
          Quiero saber más
        </CustomLink>
      </>
    ),
    validate: (input: any): boolean => {
      return /^\W|^0\.0|^0\d/.test(input) !== true
    },
  },
  {
    label: "¿Cuándo compré o vendí?",
    name: "date",
    options: timeframes,
    type: "select",
  },
]

export default params
